import logo777 from "../../assets/logo777.png";
import favbet from "../../assets/favbet.png";
import supergra from "../../assets/supergra.png";
import info from "../../assets/info.png";
import stars from "../../assets/stars.png";
import prize from "../../assets/prize.png";
import hot from "../../assets/hpt.png";
import ggbet from "../../assets/ggbet.png";
import code from "../../assets/code.png";
import k7 from "../../assets/7k.png";
import lex from "../../assets/lex.png";
import kometa from "../../assets/kometa.png";
import leebet from "../../assets/leebet.png";
import starda from "../../assets/starda.png";
import star from "../../assets/star.png";
import triangle from "../../assets/triangle.png";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  // const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");
  let pid = params.get("pid") ?? localStorage.getItem("pid");

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  // if (sub9 !== null && sub9) {
  //   localStorage.setItem("sub9", sub9);
  // }
  if (pid !== null && pid) {
    localStorage.setItem("pid", pid);
  } else {
    pid = 166;
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub2 !== null) {
      n.variables["sub3"] = sub2;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (pid !== null) {
      n.variables["sub8"] = pid;
    }
    // if (sub9 !== null) {
    //   n.variables["sub9"] = sub9;
    // }
    if (pid !== null) {
      n.variables["pid"] = pid;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://jajne.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "cometa") {
        onWebsiteCometaBtnClick();
      } else if (offer === "7k") {
        onWebsite7kBtnClick();
      } else if (offer === "leebet") {
        onWebsiteLeebetBtnClick();
      } else if (offer === "lex") {
        onWebsiteLexBtnClick();
      } else if (offer === "starda") {
        onWebsiteStardaBtnClick();
      }
      // else if (offer === "first") {
      //   onWebsiteFirstBtnClick();
      // } else if (offer === "slotoking") {
      //   onWebsiteFirstBtnClick();
      // }
    }, 3000);
  };

  function onWebsite7kBtnClick() {
    window.location.href =
      `https://wume.top/8Cdf4MWc?sub2=direct` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub3=${sub2}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (pid !== null ? `&sub8=${pid}` : "") +
      (pid !== null ? `&pid=${pid}` : "");
  }

  function onWebsiteCometaBtnClick() {
    window.location.href =
      `https://wume.top/Gq3hWF?sub2=direct` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub3=${sub2}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (pid !== null ? `&sub8=${pid}` : "") +
      (pid !== null ? `&pid=${pid}` : "");
  }

  function onWebsiteLeebetBtnClick() {
    window.location.href =
      `https://wume.top/gpYD3Rjf?sub2=direct` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub3=${sub2}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (pid !== null ? `&sub8=${pid}` : "") +
      (pid !== null ? `&pid=${pid}` : "");
  }

  function onWebsiteLexBtnClick() {
    window.location.href =
      `https://wume.top/7GwX7sK7?sub2=direct` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub3=${sub2}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (pid !== null ? `&sub8=${pid}` : "") +
      (pid !== null ? `&pid=${pid}` : "");
  }

  function onWebsiteStardaBtnClick() {
    window.location.href =
      `https://wume.top/yLrcvvLf?sub2=direct` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub3=${sub2}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (pid !== null ? `&sub8=${pid}` : "") +
      (pid !== null ? `&pid=${pid}` : "");
  }

  // function onWebsiteFirstBtnClick() {
  //   window.location.href =
  //     `https://adsboost.top/k7v7DgBR?a=b` +
  //     (sub1 !== null ? `&sub1=${sub1}` : "") +
  //     (sub2 !== null ? `&sub2=${sub2}` : "") +
  //     (sub3 !== null ? `&sub3=${sub3}` : "") +
  //     (sub4 !== null ? `&sub4=${sub4}` : "") +
  //     (sub5 !== null ? `&sub5=${sub5}` : "") +
  //     (sub6 !== null ? `&sub6=${sub6}` : "") +
  //     (sub7 !== null ? `&sub7=${sub7}` : "") +
  //     (sub8 !== null ? `&sub8=${sub8}` : "");
  // }

  return (
    <main className="main">
      <div className="container">
        <div className="top__wrapper1">
          <img src={star} alt="star" width={36} height={36} />
          <p className="main__title">лучшие казино</p>
          <img src={star} alt="star" width={36} height={36} />
        </div>
        <div className="main__thumb">
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={supergra} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Super Game</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      100 ФРІСПІНІВ
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                    </p>
                  </div> */}
          {/* <div className="main__box--two">
                    <p className="main__two--textTop">БЕЗ</p>
                    <p className="main__two--textBottom">відіграшу</p>
                  </div> */}
          {/* </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjE2NTM1&domain=mega_slots_bonus_bot",
                    "super"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}

          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={kometa} alt="Комета" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      Комета
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    {/* <p className="main__one--textTop"></p> */}
                    <p className="main__one--textBottom">
                      <svg
                        className="main__one--svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.93081 17.0692C7.84504 16.9835 7.777 16.8818 7.73056 16.7698C7.68412 16.6578 7.6602 16.5377 7.66016 16.4165C7.66011 16.2952 7.68395 16.1752 7.73031 16.0631C7.77667 15.9511 7.84464 15.8493 7.93034 15.7635C8.10343 15.5903 8.33823 15.493 8.58309 15.4929C8.70434 15.4928 8.8244 15.5167 8.93644 15.563C9.04847 15.6094 9.15027 15.6774 9.23604 15.7631C9.88146 16.4086 10.7038 16.8483 11.5992 17.0265C12.4945 17.2047 13.4225 17.1134 14.266 16.7641C15.1094 16.4148 15.8303 15.8233 16.3375 15.0643C16.8447 14.3052 17.1154 13.4129 17.1154 12.5C17.1154 12.2552 17.2127 12.0204 17.3858 11.8473C17.5589 11.6742 17.7937 11.5769 18.0385 11.5769C18.2833 11.5769 18.5181 11.6742 18.6912 11.8473C18.8643 12.0204 18.9616 12.2552 18.9616 12.5C18.9617 13.778 18.5828 15.0274 17.8728 16.0901C17.1628 17.1527 16.1536 17.981 14.9728 18.4701C13.7921 18.9592 12.4928 19.0872 11.2393 18.8378C9.98585 18.5884 8.83447 17.973 7.93081 17.0692Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M15.7871 15.1143C15.6862 15.1815 15.5731 15.2283 15.4542 15.2518C15.3352 15.2754 15.2128 15.2753 15.094 15.2516C14.9751 15.2278 14.862 15.1809 14.7613 15.1135C14.6605 15.046 14.574 14.9594 14.5068 14.8586C14.4395 14.7577 14.3927 14.6446 14.3692 14.5257C14.3456 14.4067 14.3457 14.2843 14.3694 14.1655C14.3932 14.0466 14.4401 13.9335 14.5075 13.8328C14.575 13.732 14.6616 13.6455 14.7624 13.5783L17.5317 11.7321C17.7354 11.5962 17.9847 11.5468 18.2248 11.5948C18.4649 11.6427 18.6761 11.7841 18.812 11.9878C18.9479 12.1915 18.9973 12.4408 18.9493 12.6809C18.9013 12.921 18.76 13.1322 18.5563 13.2681L15.7871 15.1143Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M20.6531 14.7569C20.7204 14.8577 20.7672 14.9708 20.7907 15.0898C20.8143 15.2087 20.8142 15.3311 20.7904 15.45C20.7667 15.5689 20.7198 15.6819 20.6523 15.7827C20.5849 15.8834 20.4983 15.9699 20.3974 16.0372C20.2966 16.1045 20.1835 16.1512 20.0645 16.1748C19.9456 16.1983 19.8232 16.1982 19.7043 16.1745C19.5854 16.1507 19.4724 16.1038 19.3716 16.0364C19.2709 15.969 19.1844 15.8823 19.1171 15.7815L17.271 13.0123C17.2037 12.9114 17.1569 12.7983 17.1334 12.6794C17.1098 12.5604 17.1099 12.438 17.1337 12.3191C17.1816 12.079 17.323 11.8678 17.5267 11.7319C17.7303 11.5961 17.9797 11.5467 18.2198 11.5946C18.3387 11.6184 18.4517 11.6653 18.5525 11.7327C18.6532 11.8002 18.7397 11.8868 18.807 11.9876L20.6531 14.7569ZM16.9839 7.74702C17.0721 7.83218 17.1424 7.93403 17.1908 8.04665C17.2391 8.15927 17.2646 8.2804 17.2657 8.40296C17.2667 8.52553 17.2434 8.64708 17.197 8.76052C17.1505 8.87396 17.082 8.97703 16.9953 9.0637C16.9087 9.15037 16.8056 9.21891 16.6922 9.26532C16.5787 9.31174 16.4572 9.33509 16.3346 9.33403C16.212 9.33296 16.0909 9.3075 15.9783 9.25912C15.8657 9.21074 15.7638 9.14042 15.6787 9.05226C15.0332 8.40661 14.2107 7.9669 13.3153 7.78874C12.4199 7.61058 11.4918 7.70199 10.6483 8.05138C9.80486 8.40078 9.08397 8.99248 8.57684 9.75164C8.0697 10.5108 7.79911 11.4033 7.79928 12.3163C7.79928 12.5611 7.70203 12.7959 7.52892 12.969C7.35581 13.1421 7.12102 13.2393 6.8762 13.2393C6.63139 13.2393 6.3966 13.1421 6.22349 12.969C6.05038 12.7959 5.95313 12.5611 5.95313 12.3163C5.95303 11.0382 6.33195 9.78885 7.04194 8.72617C7.75194 7.66349 8.76113 6.83523 9.94188 6.34613C11.1226 5.85704 12.4219 5.72907 13.6754 5.97843C14.9289 6.22779 16.0802 6.84327 16.9839 7.74702Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M9.13026 9.70207C9.33395 9.5662 9.58327 9.5168 9.82337 9.56476C10.0635 9.61271 10.2747 9.75408 10.4106 9.95776C10.5464 10.1614 10.5958 10.4108 10.5479 10.6509C10.4999 10.891 10.3586 11.1022 10.1549 11.2381L7.38472 13.0842C7.28387 13.1515 7.17075 13.1983 7.05182 13.2218C6.9329 13.2454 6.8105 13.2453 6.69161 13.2215C6.57272 13.1978 6.45968 13.1509 6.35893 13.0834C6.25817 13.016 6.17169 12.9294 6.10441 12.8285C5.96854 12.6248 5.91915 12.3755 5.9671 12.1354C6.01505 11.8953 6.15642 11.6841 6.36011 11.5482L9.13026 9.70207Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M4.26066 10.0593C4.19338 9.95842 4.14663 9.8453 4.12307 9.72638C4.09951 9.60746 4.0996 9.48506 4.12335 9.36617C4.14709 9.24728 4.19402 9.13424 4.26145 9.03348C4.32888 8.93273 4.4155 8.84625 4.51635 8.77897C4.61721 8.71169 4.73033 8.66494 4.84925 8.64138C4.96817 8.61782 5.09057 8.61791 5.20946 8.64166C5.32835 8.6654 5.4414 8.71233 5.54215 8.77976C5.6429 8.84719 5.72938 8.93381 5.79666 9.03466L7.64281 11.8039C7.77869 12.0076 7.82808 12.2569 7.78013 12.497C7.73218 12.7371 7.59081 12.9483 7.38712 13.0842C7.18344 13.2201 6.93412 13.2695 6.69401 13.2215C6.45391 13.1736 6.24269 13.0322 6.10681 12.8285L4.26066 10.0593Z"
                          fill="#FFD600"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5 22.6538C18.1077 22.6538 22.6538 18.1077 22.6538 12.5C22.6538 6.89231 18.1077 2.34615 12.5 2.34615C6.89231 2.34615 2.34615 6.89231 2.34615 12.5C2.34615 18.1077 6.89231 22.6538 12.5 22.6538ZM12.5 24.5C19.1277 24.5 24.5 19.1277 24.5 12.5C24.5 5.87231 19.1277 0.5 12.5 0.5C5.87231 0.5 0.5 5.87231 0.5 12.5C0.5 19.1277 5.87231 24.5 12.5 24.5Z"
                          fill="#FFD600"
                        />
                      </svg>
                      500 FS
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    {/* <p className="main__two--textTop"></p> */}
                    <p className="main__two--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      255% к депозиту
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новый бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main--button--top"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjMzNTY3&domain=fireslot_bonus_bot",
                    "cometa"
                  );
                }}
              >
                <div className="main__wrap--top">
                  <p className="main__text--btm">Получить код</p>
                  <svg
                    className="main__svg--arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </svg>
                </div>
                <img
                  src={triangle}
                  alt="трикутник"
                  className="main__svg--triangle"
                />
                <p className="main__btn--text">q10mt</p>
                <svg
                  className="main__paralelogram"
                  width="137"
                  height="91"
                  viewBox="0 0 137 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M137 0L137 91H0.5L32.2642 0H137Z"
                    fill="url(#paint0_linear_1963_16832)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1963_16832"
                      x1="0.499999"
                      y1="9.89131"
                      x2="147.863"
                      y2="57.0723"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF0D09" />
                      <stop offset="1" stop-color="#FF5C00" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={k7} alt="7k" className="main__logo" />
                  <div>
                    <p className="main__top--title">7К</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    {/* <p className="main__one--textTop">ФРІСПІНИ</p> */}
                    <p className="main__one--textBottom">
                      <svg
                        className="main__one--svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.93081 17.0692C7.84504 16.9835 7.777 16.8818 7.73056 16.7698C7.68412 16.6578 7.6602 16.5377 7.66016 16.4165C7.66011 16.2952 7.68395 16.1752 7.73031 16.0631C7.77667 15.9511 7.84464 15.8493 7.93034 15.7635C8.10343 15.5903 8.33823 15.493 8.58309 15.4929C8.70434 15.4928 8.8244 15.5167 8.93644 15.563C9.04847 15.6094 9.15027 15.6774 9.23604 15.7631C9.88146 16.4086 10.7038 16.8483 11.5992 17.0265C12.4945 17.2047 13.4225 17.1134 14.266 16.7641C15.1094 16.4148 15.8303 15.8233 16.3375 15.0643C16.8447 14.3052 17.1154 13.4129 17.1154 12.5C17.1154 12.2552 17.2127 12.0204 17.3858 11.8473C17.5589 11.6742 17.7937 11.5769 18.0385 11.5769C18.2833 11.5769 18.5181 11.6742 18.6912 11.8473C18.8643 12.0204 18.9616 12.2552 18.9616 12.5C18.9617 13.778 18.5828 15.0274 17.8728 16.0901C17.1628 17.1527 16.1536 17.981 14.9728 18.4701C13.7921 18.9592 12.4928 19.0872 11.2393 18.8378C9.98585 18.5884 8.83447 17.973 7.93081 17.0692Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M15.7871 15.1143C15.6862 15.1815 15.5731 15.2283 15.4542 15.2518C15.3352 15.2754 15.2128 15.2753 15.094 15.2516C14.9751 15.2278 14.862 15.1809 14.7613 15.1135C14.6605 15.046 14.574 14.9594 14.5068 14.8586C14.4395 14.7577 14.3927 14.6446 14.3692 14.5257C14.3456 14.4067 14.3457 14.2843 14.3694 14.1655C14.3932 14.0466 14.4401 13.9335 14.5075 13.8328C14.575 13.732 14.6616 13.6455 14.7624 13.5783L17.5317 11.7321C17.7354 11.5962 17.9847 11.5468 18.2248 11.5948C18.4649 11.6427 18.6761 11.7841 18.812 11.9878C18.9479 12.1915 18.9973 12.4408 18.9493 12.6809C18.9013 12.921 18.76 13.1322 18.5563 13.2681L15.7871 15.1143Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M20.6531 14.7569C20.7204 14.8577 20.7672 14.9708 20.7907 15.0898C20.8143 15.2087 20.8142 15.3311 20.7904 15.45C20.7667 15.5689 20.7198 15.6819 20.6523 15.7827C20.5849 15.8834 20.4983 15.9699 20.3974 16.0372C20.2966 16.1045 20.1835 16.1512 20.0645 16.1748C19.9456 16.1983 19.8232 16.1982 19.7043 16.1745C19.5854 16.1507 19.4724 16.1038 19.3716 16.0364C19.2709 15.969 19.1844 15.8823 19.1171 15.7815L17.271 13.0123C17.2037 12.9114 17.1569 12.7983 17.1334 12.6794C17.1098 12.5604 17.1099 12.438 17.1337 12.3191C17.1816 12.079 17.323 11.8678 17.5267 11.7319C17.7303 11.5961 17.9797 11.5467 18.2198 11.5946C18.3387 11.6184 18.4517 11.6653 18.5525 11.7327C18.6532 11.8002 18.7397 11.8868 18.807 11.9876L20.6531 14.7569ZM16.9839 7.74702C17.0721 7.83218 17.1424 7.93403 17.1908 8.04665C17.2391 8.15927 17.2646 8.2804 17.2657 8.40296C17.2667 8.52553 17.2434 8.64708 17.197 8.76052C17.1505 8.87396 17.082 8.97703 16.9953 9.0637C16.9087 9.15037 16.8056 9.21891 16.6922 9.26532C16.5787 9.31174 16.4572 9.33509 16.3346 9.33403C16.212 9.33296 16.0909 9.3075 15.9783 9.25912C15.8657 9.21074 15.7638 9.14042 15.6787 9.05226C15.0332 8.40661 14.2107 7.9669 13.3153 7.78874C12.4199 7.61058 11.4918 7.70199 10.6483 8.05138C9.80486 8.40078 9.08397 8.99248 8.57684 9.75164C8.0697 10.5108 7.79911 11.4033 7.79928 12.3163C7.79928 12.5611 7.70203 12.7959 7.52892 12.969C7.35581 13.1421 7.12102 13.2393 6.8762 13.2393C6.63139 13.2393 6.3966 13.1421 6.22349 12.969C6.05038 12.7959 5.95313 12.5611 5.95313 12.3163C5.95303 11.0382 6.33195 9.78885 7.04194 8.72617C7.75194 7.66349 8.76113 6.83523 9.94188 6.34613C11.1226 5.85704 12.4219 5.72907 13.6754 5.97843C14.9289 6.22779 16.0802 6.84327 16.9839 7.74702Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M9.13026 9.70207C9.33395 9.5662 9.58327 9.5168 9.82337 9.56476C10.0635 9.61271 10.2747 9.75408 10.4106 9.95776C10.5464 10.1614 10.5958 10.4108 10.5479 10.6509C10.4999 10.891 10.3586 11.1022 10.1549 11.2381L7.38472 13.0842C7.28387 13.1515 7.17075 13.1983 7.05182 13.2218C6.9329 13.2454 6.8105 13.2453 6.69161 13.2215C6.57272 13.1978 6.45968 13.1509 6.35893 13.0834C6.25817 13.016 6.17169 12.9294 6.10441 12.8285C5.96854 12.6248 5.91915 12.3755 5.9671 12.1354C6.01505 11.8953 6.15642 11.6841 6.36011 11.5482L9.13026 9.70207Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M4.26066 10.0593C4.19338 9.95842 4.14663 9.8453 4.12307 9.72638C4.09951 9.60746 4.0996 9.48506 4.12335 9.36617C4.14709 9.24728 4.19402 9.13424 4.26145 9.03348C4.32888 8.93273 4.4155 8.84625 4.51635 8.77897C4.61721 8.71169 4.73033 8.66494 4.84925 8.64138C4.96817 8.61782 5.09057 8.61791 5.20946 8.64166C5.32835 8.6654 5.4414 8.71233 5.54215 8.77976C5.6429 8.84719 5.72938 8.93381 5.79666 9.03466L7.64281 11.8039C7.77869 12.0076 7.82808 12.2569 7.78013 12.497C7.73218 12.7371 7.59081 12.9483 7.38712 13.0842C7.18344 13.2201 6.93412 13.2695 6.69401 13.2215C6.45391 13.1736 6.24269 13.0322 6.10681 12.8285L4.26066 10.0593Z"
                          fill="#FFD600"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5 22.6538C18.1077 22.6538 22.6538 18.1077 22.6538 12.5C22.6538 6.89231 18.1077 2.34615 12.5 2.34615C6.89231 2.34615 2.34615 6.89231 2.34615 12.5C2.34615 18.1077 6.89231 22.6538 12.5 22.6538ZM12.5 24.5C19.1277 24.5 24.5 19.1277 24.5 12.5C24.5 5.87231 19.1277 0.5 12.5 0.5C5.87231 0.5 0.5 5.87231 0.5 12.5C0.5 19.1277 5.87231 24.5 12.5 24.5Z"
                          fill="#FFD600"
                        />
                      </svg>
                      400 FS
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      100 000 руб
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новый бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main--button--top"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjMzNTcw&domain=fireslot_bonus_bot",
                    "7k"
                  );
                }}
              >
                <div className="main__wrap--top">
                  <p className="main__text--btm">Получить код</p>
                  <svg
                    className="main__svg--arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </svg>
                </div>
                <img
                  src={triangle}
                  alt="трикутник"
                  className="main__svg--triangle"
                />
                <p className="main__btn--text">q10mt</p>
                <svg
                  className="main__paralelogram"
                  width="137"
                  height="91"
                  viewBox="0 0 137 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M137 0L137 91H0.5L32.2642 0H137Z"
                    fill="url(#paint0_linear_1963_16832)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1963_16832"
                      x1="0.499999"
                      y1="9.89131"
                      x2="147.863"
                      y2="57.0723"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF0D09" />
                      <stop offset="1" stop-color="#FF5C00" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={leebet} alt="Leebet" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      LeeBet
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    {/* <p className="main__one--textTop"></p> */}
                    <p className="main__one--textBottom">
                      <svg
                        className="main__one--svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.93081 17.0692C7.84504 16.9835 7.777 16.8818 7.73056 16.7698C7.68412 16.6578 7.6602 16.5377 7.66016 16.4165C7.66011 16.2952 7.68395 16.1752 7.73031 16.0631C7.77667 15.9511 7.84464 15.8493 7.93034 15.7635C8.10343 15.5903 8.33823 15.493 8.58309 15.4929C8.70434 15.4928 8.8244 15.5167 8.93644 15.563C9.04847 15.6094 9.15027 15.6774 9.23604 15.7631C9.88146 16.4086 10.7038 16.8483 11.5992 17.0265C12.4945 17.2047 13.4225 17.1134 14.266 16.7641C15.1094 16.4148 15.8303 15.8233 16.3375 15.0643C16.8447 14.3052 17.1154 13.4129 17.1154 12.5C17.1154 12.2552 17.2127 12.0204 17.3858 11.8473C17.5589 11.6742 17.7937 11.5769 18.0385 11.5769C18.2833 11.5769 18.5181 11.6742 18.6912 11.8473C18.8643 12.0204 18.9616 12.2552 18.9616 12.5C18.9617 13.778 18.5828 15.0274 17.8728 16.0901C17.1628 17.1527 16.1536 17.981 14.9728 18.4701C13.7921 18.9592 12.4928 19.0872 11.2393 18.8378C9.98585 18.5884 8.83447 17.973 7.93081 17.0692Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M15.7871 15.1143C15.6862 15.1815 15.5731 15.2283 15.4542 15.2518C15.3352 15.2754 15.2128 15.2753 15.094 15.2516C14.9751 15.2278 14.862 15.1809 14.7613 15.1135C14.6605 15.046 14.574 14.9594 14.5068 14.8586C14.4395 14.7577 14.3927 14.6446 14.3692 14.5257C14.3456 14.4067 14.3457 14.2843 14.3694 14.1655C14.3932 14.0466 14.4401 13.9335 14.5075 13.8328C14.575 13.732 14.6616 13.6455 14.7624 13.5783L17.5317 11.7321C17.7354 11.5962 17.9847 11.5468 18.2248 11.5948C18.4649 11.6427 18.6761 11.7841 18.812 11.9878C18.9479 12.1915 18.9973 12.4408 18.9493 12.6809C18.9013 12.921 18.76 13.1322 18.5563 13.2681L15.7871 15.1143Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M20.6531 14.7569C20.7204 14.8577 20.7672 14.9708 20.7907 15.0898C20.8143 15.2087 20.8142 15.3311 20.7904 15.45C20.7667 15.5689 20.7198 15.6819 20.6523 15.7827C20.5849 15.8834 20.4983 15.9699 20.3974 16.0372C20.2966 16.1045 20.1835 16.1512 20.0645 16.1748C19.9456 16.1983 19.8232 16.1982 19.7043 16.1745C19.5854 16.1507 19.4724 16.1038 19.3716 16.0364C19.2709 15.969 19.1844 15.8823 19.1171 15.7815L17.271 13.0123C17.2037 12.9114 17.1569 12.7983 17.1334 12.6794C17.1098 12.5604 17.1099 12.438 17.1337 12.3191C17.1816 12.079 17.323 11.8678 17.5267 11.7319C17.7303 11.5961 17.9797 11.5467 18.2198 11.5946C18.3387 11.6184 18.4517 11.6653 18.5525 11.7327C18.6532 11.8002 18.7397 11.8868 18.807 11.9876L20.6531 14.7569ZM16.9839 7.74702C17.0721 7.83218 17.1424 7.93403 17.1908 8.04665C17.2391 8.15927 17.2646 8.2804 17.2657 8.40296C17.2667 8.52553 17.2434 8.64708 17.197 8.76052C17.1505 8.87396 17.082 8.97703 16.9953 9.0637C16.9087 9.15037 16.8056 9.21891 16.6922 9.26532C16.5787 9.31174 16.4572 9.33509 16.3346 9.33403C16.212 9.33296 16.0909 9.3075 15.9783 9.25912C15.8657 9.21074 15.7638 9.14042 15.6787 9.05226C15.0332 8.40661 14.2107 7.9669 13.3153 7.78874C12.4199 7.61058 11.4918 7.70199 10.6483 8.05138C9.80486 8.40078 9.08397 8.99248 8.57684 9.75164C8.0697 10.5108 7.79911 11.4033 7.79928 12.3163C7.79928 12.5611 7.70203 12.7959 7.52892 12.969C7.35581 13.1421 7.12102 13.2393 6.8762 13.2393C6.63139 13.2393 6.3966 13.1421 6.22349 12.969C6.05038 12.7959 5.95313 12.5611 5.95313 12.3163C5.95303 11.0382 6.33195 9.78885 7.04194 8.72617C7.75194 7.66349 8.76113 6.83523 9.94188 6.34613C11.1226 5.85704 12.4219 5.72907 13.6754 5.97843C14.9289 6.22779 16.0802 6.84327 16.9839 7.74702Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M9.13026 9.70207C9.33395 9.5662 9.58327 9.5168 9.82337 9.56476C10.0635 9.61271 10.2747 9.75408 10.4106 9.95776C10.5464 10.1614 10.5958 10.4108 10.5479 10.6509C10.4999 10.891 10.3586 11.1022 10.1549 11.2381L7.38472 13.0842C7.28387 13.1515 7.17075 13.1983 7.05182 13.2218C6.9329 13.2454 6.8105 13.2453 6.69161 13.2215C6.57272 13.1978 6.45968 13.1509 6.35893 13.0834C6.25817 13.016 6.17169 12.9294 6.10441 12.8285C5.96854 12.6248 5.91915 12.3755 5.9671 12.1354C6.01505 11.8953 6.15642 11.6841 6.36011 11.5482L9.13026 9.70207Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M4.26066 10.0593C4.19338 9.95842 4.14663 9.8453 4.12307 9.72638C4.09951 9.60746 4.0996 9.48506 4.12335 9.36617C4.14709 9.24728 4.19402 9.13424 4.26145 9.03348C4.32888 8.93273 4.4155 8.84625 4.51635 8.77897C4.61721 8.71169 4.73033 8.66494 4.84925 8.64138C4.96817 8.61782 5.09057 8.61791 5.20946 8.64166C5.32835 8.6654 5.4414 8.71233 5.54215 8.77976C5.6429 8.84719 5.72938 8.93381 5.79666 9.03466L7.64281 11.8039C7.77869 12.0076 7.82808 12.2569 7.78013 12.497C7.73218 12.7371 7.59081 12.9483 7.38712 13.0842C7.18344 13.2201 6.93412 13.2695 6.69401 13.2215C6.45391 13.1736 6.24269 13.0322 6.10681 12.8285L4.26066 10.0593Z"
                          fill="#FFD600"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5 22.6538C18.1077 22.6538 22.6538 18.1077 22.6538 12.5C22.6538 6.89231 18.1077 2.34615 12.5 2.34615C6.89231 2.34615 2.34615 6.89231 2.34615 12.5C2.34615 18.1077 6.89231 22.6538 12.5 22.6538ZM12.5 24.5C19.1277 24.5 24.5 19.1277 24.5 12.5C24.5 5.87231 19.1277 0.5 12.5 0.5C5.87231 0.5 0.5 5.87231 0.5 12.5C0.5 19.1277 5.87231 24.5 12.5 24.5Z"
                          fill="#FFD600"
                        />
                      </svg>
                      150 FS
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    {/* <p className="main__two--textTop"></p> */}
                    <p className="main__two--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      150% к депозиту
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новый бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main--button--top"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjMzNTcz&domain=fireslot_bonus_bot",
                    "leebet"
                  );
                }}
              >
                <div className="main__wrap--top">
                  <p className="main__text--btm">Получить код</p>
                  <svg
                    className="main__svg--arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </svg>
                </div>
                <img
                  src={triangle}
                  alt="трикутник"
                  className="main__svg--triangle"
                />
                <p className="main__btn--text">q10mt</p>
                <svg
                  className="main__paralelogram"
                  width="137"
                  height="91"
                  viewBox="0 0 137 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M137 0L137 91H0.5L32.2642 0H137Z"
                    fill="url(#paint0_linear_1963_16832)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1963_16832"
                      x1="0.499999"
                      y1="9.89131"
                      x2="147.863"
                      y2="57.0723"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF0D09" />
                      <stop offset="1" stop-color="#FF5C00" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={lex} alt="Lex" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      Lex
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    {/* <p className="main__one--textTop"></p> */}
                    <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      150% к депозиту
                    </p>
                  </div>
                  {/* <div className="main__box--two" style={{ width: "50%" }}> */}
                  {/* <p className="main__two--textTop"></p> */}
                  {/* <p className="main__two--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      150% к депозиту
                    </p>
                  </div> */}
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новый бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main--button--top"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjMzNTc2&domain=fireslot_bonus_bot",
                    "lex"
                  );
                }}
              >
                <div className="main__wrap--top">
                  <p className="main__text--btm">Получить код</p>
                  <svg
                    className="main__svg--arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </svg>
                </div>
                <img
                  src={triangle}
                  alt="трикутник"
                  className="main__svg--triangle"
                />
                <p className="main__btn--text">q10mt</p>
                <svg
                  className="main__paralelogram"
                  width="137"
                  height="91"
                  viewBox="0 0 137 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M137 0L137 91H0.5L32.2642 0H137Z"
                    fill="url(#paint0_linear_1963_16832)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1963_16832"
                      x1="0.499999"
                      y1="9.89131"
                      x2="147.863"
                      y2="57.0723"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF0D09" />
                      <stop offset="1" stop-color="#FF5C00" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={starda} alt="Starda" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      Starda
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    {/* <p className="main__one--textTop"></p> */}
                    <p className="main__one--textBottom">
                      <svg
                        className="main__one--svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.93081 17.0692C7.84504 16.9835 7.777 16.8818 7.73056 16.7698C7.68412 16.6578 7.6602 16.5377 7.66016 16.4165C7.66011 16.2952 7.68395 16.1752 7.73031 16.0631C7.77667 15.9511 7.84464 15.8493 7.93034 15.7635C8.10343 15.5903 8.33823 15.493 8.58309 15.4929C8.70434 15.4928 8.8244 15.5167 8.93644 15.563C9.04847 15.6094 9.15027 15.6774 9.23604 15.7631C9.88146 16.4086 10.7038 16.8483 11.5992 17.0265C12.4945 17.2047 13.4225 17.1134 14.266 16.7641C15.1094 16.4148 15.8303 15.8233 16.3375 15.0643C16.8447 14.3052 17.1154 13.4129 17.1154 12.5C17.1154 12.2552 17.2127 12.0204 17.3858 11.8473C17.5589 11.6742 17.7937 11.5769 18.0385 11.5769C18.2833 11.5769 18.5181 11.6742 18.6912 11.8473C18.8643 12.0204 18.9616 12.2552 18.9616 12.5C18.9617 13.778 18.5828 15.0274 17.8728 16.0901C17.1628 17.1527 16.1536 17.981 14.9728 18.4701C13.7921 18.9592 12.4928 19.0872 11.2393 18.8378C9.98585 18.5884 8.83447 17.973 7.93081 17.0692Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M15.7871 15.1143C15.6862 15.1815 15.5731 15.2283 15.4542 15.2518C15.3352 15.2754 15.2128 15.2753 15.094 15.2516C14.9751 15.2278 14.862 15.1809 14.7613 15.1135C14.6605 15.046 14.574 14.9594 14.5068 14.8586C14.4395 14.7577 14.3927 14.6446 14.3692 14.5257C14.3456 14.4067 14.3457 14.2843 14.3694 14.1655C14.3932 14.0466 14.4401 13.9335 14.5075 13.8328C14.575 13.732 14.6616 13.6455 14.7624 13.5783L17.5317 11.7321C17.7354 11.5962 17.9847 11.5468 18.2248 11.5948C18.4649 11.6427 18.6761 11.7841 18.812 11.9878C18.9479 12.1915 18.9973 12.4408 18.9493 12.6809C18.9013 12.921 18.76 13.1322 18.5563 13.2681L15.7871 15.1143Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M20.6531 14.7569C20.7204 14.8577 20.7672 14.9708 20.7907 15.0898C20.8143 15.2087 20.8142 15.3311 20.7904 15.45C20.7667 15.5689 20.7198 15.6819 20.6523 15.7827C20.5849 15.8834 20.4983 15.9699 20.3974 16.0372C20.2966 16.1045 20.1835 16.1512 20.0645 16.1748C19.9456 16.1983 19.8232 16.1982 19.7043 16.1745C19.5854 16.1507 19.4724 16.1038 19.3716 16.0364C19.2709 15.969 19.1844 15.8823 19.1171 15.7815L17.271 13.0123C17.2037 12.9114 17.1569 12.7983 17.1334 12.6794C17.1098 12.5604 17.1099 12.438 17.1337 12.3191C17.1816 12.079 17.323 11.8678 17.5267 11.7319C17.7303 11.5961 17.9797 11.5467 18.2198 11.5946C18.3387 11.6184 18.4517 11.6653 18.5525 11.7327C18.6532 11.8002 18.7397 11.8868 18.807 11.9876L20.6531 14.7569ZM16.9839 7.74702C17.0721 7.83218 17.1424 7.93403 17.1908 8.04665C17.2391 8.15927 17.2646 8.2804 17.2657 8.40296C17.2667 8.52553 17.2434 8.64708 17.197 8.76052C17.1505 8.87396 17.082 8.97703 16.9953 9.0637C16.9087 9.15037 16.8056 9.21891 16.6922 9.26532C16.5787 9.31174 16.4572 9.33509 16.3346 9.33403C16.212 9.33296 16.0909 9.3075 15.9783 9.25912C15.8657 9.21074 15.7638 9.14042 15.6787 9.05226C15.0332 8.40661 14.2107 7.9669 13.3153 7.78874C12.4199 7.61058 11.4918 7.70199 10.6483 8.05138C9.80486 8.40078 9.08397 8.99248 8.57684 9.75164C8.0697 10.5108 7.79911 11.4033 7.79928 12.3163C7.79928 12.5611 7.70203 12.7959 7.52892 12.969C7.35581 13.1421 7.12102 13.2393 6.8762 13.2393C6.63139 13.2393 6.3966 13.1421 6.22349 12.969C6.05038 12.7959 5.95313 12.5611 5.95313 12.3163C5.95303 11.0382 6.33195 9.78885 7.04194 8.72617C7.75194 7.66349 8.76113 6.83523 9.94188 6.34613C11.1226 5.85704 12.4219 5.72907 13.6754 5.97843C14.9289 6.22779 16.0802 6.84327 16.9839 7.74702Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M9.13026 9.70207C9.33395 9.5662 9.58327 9.5168 9.82337 9.56476C10.0635 9.61271 10.2747 9.75408 10.4106 9.95776C10.5464 10.1614 10.5958 10.4108 10.5479 10.6509C10.4999 10.891 10.3586 11.1022 10.1549 11.2381L7.38472 13.0842C7.28387 13.1515 7.17075 13.1983 7.05182 13.2218C6.9329 13.2454 6.8105 13.2453 6.69161 13.2215C6.57272 13.1978 6.45968 13.1509 6.35893 13.0834C6.25817 13.016 6.17169 12.9294 6.10441 12.8285C5.96854 12.6248 5.91915 12.3755 5.9671 12.1354C6.01505 11.8953 6.15642 11.6841 6.36011 11.5482L9.13026 9.70207Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M4.26066 10.0593C4.19338 9.95842 4.14663 9.8453 4.12307 9.72638C4.09951 9.60746 4.0996 9.48506 4.12335 9.36617C4.14709 9.24728 4.19402 9.13424 4.26145 9.03348C4.32888 8.93273 4.4155 8.84625 4.51635 8.77897C4.61721 8.71169 4.73033 8.66494 4.84925 8.64138C4.96817 8.61782 5.09057 8.61791 5.20946 8.64166C5.32835 8.6654 5.4414 8.71233 5.54215 8.77976C5.6429 8.84719 5.72938 8.93381 5.79666 9.03466L7.64281 11.8039C7.77869 12.0076 7.82808 12.2569 7.78013 12.497C7.73218 12.7371 7.59081 12.9483 7.38712 13.0842C7.18344 13.2201 6.93412 13.2695 6.69401 13.2215C6.45391 13.1736 6.24269 13.0322 6.10681 12.8285L4.26066 10.0593Z"
                          fill="#FFD600"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5 22.6538C18.1077 22.6538 22.6538 18.1077 22.6538 12.5C22.6538 6.89231 18.1077 2.34615 12.5 2.34615C6.89231 2.34615 2.34615 6.89231 2.34615 12.5C2.34615 18.1077 6.89231 22.6538 12.5 22.6538ZM12.5 24.5C19.1277 24.5 24.5 19.1277 24.5 12.5C24.5 5.87231 19.1277 0.5 12.5 0.5C5.87231 0.5 0.5 5.87231 0.5 12.5C0.5 19.1277 5.87231 24.5 12.5 24.5Z"
                          fill="#FFD600"
                        />
                      </svg>
                      500 FS
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    {/* <p className="main__two--textTop"></p> */}
                    <p className="main__two--textBottom">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7782 20.2782C17.7153 22.3411 14.9174 23.5 12 23.5C9.08262 23.5 6.28473 22.3411 4.22183 20.2782C2.15893 18.2153 1 15.4174 1 12.5C1 9.58262 2.15893 6.78473 4.22183 4.72183C6.28473 2.65893 9.08262 1.5 12 1.5C14.9174 1.5 17.7153 2.65893 19.7782 4.72182C21.8411 6.78473 23 9.58262 23 12.5C23 15.4174 21.8411 18.2153 19.7782 20.2782Z"
                          stroke="#FFD600"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9607 10.496C15.9607 9.87085 15.7575 9.36445 15.3512 8.97684C14.9448 8.58922 14.4103 8.39542 13.7476 8.39542H11.2624C10.9776 8.39542 10.7467 8.62634 10.7467 8.91119V12.0809C10.7467 12.3657 10.9776 12.5967 11.2624 12.5967H13.7476C14.4103 12.5967 14.9448 12.4029 15.3512 12.0152C15.7575 11.6276 15.9607 11.1212 15.9607 10.496ZM18.1832 10.496C18.1832 11.7026 17.7878 12.6873 16.997 13.45C16.2061 14.2128 15.1855 14.5941 13.9351 14.5941H11.2624C10.9776 14.5941 10.7467 14.825 10.7467 15.1099V15.1849C10.7467 15.4698 10.9776 15.7007 11.2624 15.7007H15.4824C15.57 15.7007 15.6419 15.7288 15.6981 15.7851C15.7544 15.8414 15.7825 15.9133 15.7825 16.0008V17.2011C15.7825 17.2887 15.7544 17.3606 15.6981 17.4168C15.6419 17.4731 15.57 17.5012 15.4824 17.5012H11.2624C10.9776 17.5012 10.7467 17.7322 10.7467 18.017V19.3018C10.7467 19.3893 10.717 19.4612 10.6576 19.5175C10.5982 19.5737 10.5279 19.6019 10.4466 19.6019H8.88049C8.79296 19.6019 8.72107 19.5737 8.6648 19.5175C8.60853 19.4612 8.5804 19.3893 8.5804 19.3018V18.017C8.5804 17.7322 8.34948 17.5012 8.06462 17.5012H6.47978C6.39225 17.5012 6.32035 17.4731 6.26409 17.4168C6.20782 17.3606 6.17969 17.2887 6.17969 17.2011V16.0008C6.17969 15.9133 6.20782 15.8414 6.26409 15.7851C6.32035 15.7288 6.39225 15.7007 6.47978 15.7007H8.06462C8.34948 15.7007 8.5804 15.4698 8.5804 15.1849V15.1099C8.5804 14.825 8.34948 14.5941 8.06462 14.5941H6.47978C6.39225 14.5941 6.32035 14.566 6.26409 14.5097C6.20782 14.4535 6.17969 14.3816 6.17969 14.294V12.8967C6.17969 12.8155 6.20782 12.7451 6.26409 12.6857C6.32035 12.6264 6.39225 12.5967 6.47978 12.5967H8.06462C8.34948 12.5967 8.5804 12.3657 8.5804 12.0809V6.69804C8.5804 6.61051 8.60853 6.53862 8.6648 6.48235C8.72107 6.42608 8.79296 6.39795 8.88049 6.39795H13.9351C15.1855 6.39795 16.2061 6.77931 16.997 7.54204C17.7878 8.30476 18.1832 9.28943 18.1832 10.496Z"
                          fill="#FFD600"
                        />
                      </svg>
                      100% к депозиту
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новый бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main--button--top"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjMzNTc5&domain=fireslot_bonus_bot",
                    "starda"
                  );
                }}
              >
                <div className="main__wrap--top">
                  <p className="main__text--btm">Получить код</p>
                  <svg
                    className="main__svg--arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </svg>
                </div>
                <img
                  src={triangle}
                  alt="трикутник"
                  className="main__svg--triangle"
                />
                <p className="main__btn--text">q10mt</p>
                <svg
                  className="main__paralelogram"
                  width="137"
                  height="91"
                  viewBox="0 0 137 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M137 0L137 91H0.5L32.2642 0H137Z"
                    fill="url(#paint0_linear_1963_16832)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1963_16832"
                      x1="0.499999"
                      y1="9.89131"
                      x2="147.863"
                      y2="57.0723"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF0D09" />
                      <stop offset="1" stop-color="#FF5C00" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--two" style={{ width: "100%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНІВ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      100 ФРІСПІНІВ
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjQ3&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={code} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНІВ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      25 ФРІСПІНІВ
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">промокод</p>
                    <p className="main__two--textBottom">fav25fs</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA4NDAy&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={ggbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">GG</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНИ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      50 ФРІСПІНІВ
                    </p>
                  </div>
                  <div
                    className="main__box--two"
                    style={{
                      width: "50%",
                    }}
                  > */}
          {/* <p className="main__two--textTop">БЕЗ</p> */}
          {/* <p
                      className="main__two--textBottom"
                      style={{
                        fontSize: "19px",
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.2782 20.2782C18.2153 22.3411 15.4174 23.5 12.5 23.5C9.58262 23.5 6.78473 22.3411 4.72183 20.2782C2.65893 18.2153 1.5 15.4174 1.5 12.5C1.5 9.58262 2.65893 6.78473 4.72183 4.72183C6.78473 2.65893 9.58262 1.5 12.5 1.5C15.4174 1.5 18.2153 2.65893 20.2782 4.72182C22.3411 6.78473 23.5 9.58262 23.5 12.5C23.5 15.4174 22.3411 18.2153 20.2782 20.2782Z"
                          stroke="#4AFFAE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.71094 15.5732C8.79153 16.5028 9.23366 17.3637 9.94229 17.9708C10.6509 18.5778 11.5694 18.8826 12.5004 18.8195C15.5573 18.8195 16.2899 17.0637 16.2899 15.5732C16.2899 14.0827 15.0267 12.5037 12.5004 12.5037C9.9741 12.5037 8.71094 11.5058 8.71094 9.47217C8.73449 9.00431 8.85244 8.54605 9.05768 8.12496C9.26291 7.70386 9.5512 7.32863 9.9052 7.02183C10.2592 6.71502 10.6716 6.483 11.1176 6.33969C11.5636 6.19639 12.034 6.14478 12.5004 6.18795C13.432 6.14784 14.3436 6.46574 15.0483 7.07644C15.7529 7.68714 16.1972 8.54433 16.2899 9.47217M12.5004 20.7105V19.0684M12.5004 4.28943V6.1829"
                          stroke="#4AFFAE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      12,000 грн
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новий бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjE2NTI5&domain=mega_slots_bonus_bot",
                    "ggbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={logo777} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">777</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textTop">БОНУС</p>
                    <p className="main__one--textBottom">200 грн</p>
                  </div>
                   <div className="main__box--two">
                    <p className="main__two--textTop">БОНУС</p>
                    <p className="main__two--textBottom">
                      10<span style={{ letterSpacing: "0.1em" }}>0</span>00
                      <span style={{ letterSpacing: "0.1em" }}>0</span>₴
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjUz&domain=free_spins_ua_bot",
                    "777"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  );
};
